<template>
  <b-container>
    <b-row>
      <b-col cols="3" class="mt-4">
        <template v-if="$options.components['health-care-category']">
          <health-care-category />
        </template>
      </b-col>
      <b-col cols="9" class="my-4">
        <template
          v-if="$options.components['advertisement-healthians-top-banner']"
        >
          <advertisement-healthians-top-banner />
        </template>
        <span class="text-grey1">Home > Looking for a doctor </span>

        <sections-slider
          :list="healthProducts"
          :hasInnerBackground="true"
          :hasButton="false"
          :withSlider="true"
        >
          <template slot="default" slot-scope="{ listItem }">
            <items-healthian-product
              :product="listItem"
            ></items-healthian-product>
          </template>
        </sections-slider>
        <div class="address-banner-box mb-3">
          <div>
            <b-img src="img/address_banner.png" fluid />
          </div>
          <div class="over-img-box">
            <div class="text-box">
              <p>Selected Pickup Location</p>
            </div>
            <div class="adress-box p-3 mt-2">
              <div v-if="isServiceAvailable">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <b-icon
                      icon="arrow-up-circle-fill"
                      class="mr-3 icon-col"
                    ></b-icon>
                    <h6>{{ defaultAddress ? defaultAddress.fullName : "" }}</h6>
                  </div>
                  <div class="d-flex default-class">
                    <b-icon icon="check2" class="mr-1" font-scale="1"></b-icon>
                    <p>Default</p>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <b-icon
                    icon="geo-alt-fill"
                    class="mr-3 mt-1 icon-col"
                  ></b-icon>
                  <p>
                    {{ defaultAddress ? defaultAddress.houseNumber : "" }},
                    {{ defaultAddress ? defaultAddress.street2 : "" }},
                    {{ defaultAddress ? defaultAddress.city : "" }},<br />
                    {{ defaultAddress ? defaultAddress.state : "" }}, PIN-
                    {{ defaultAddress ? defaultAddress.zip : "" }}
                  </p>
                </div>
              </div>
              <p v-else>Please add a default address</p>
            </div>
            <div class="button-box d-flex justify-content-end mt-2">
              <b-button
                type="button"
                class="button-text mr-3"
                variant="outline-primary"
                @click="
                  () => {
                    editAddressClick(defaultAddress);
                    $bvModal.show(defaultAddress._id);
                  }
                "
                v-if="isServiceAvailable"
              >
                Edit Address
              </b-button>
              <b-modal
                :id="defaultAddress._id"
                size="lg"
                :hide-footer="true"
                :hide-header="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
                centered
                header-class="my-class"
                body-class="p-0"
              >
                <template
                  v-if="$options.components['address-edit-address-modal']"
                >
                  <address-edit-address-modal
                    ref="editAddressRef"
                    :address="defaultAddress"
                  />
                </template>
              </b-modal>
              <b-button
                type="button"
                class="button-text ml-3"
                variant="primary"
                @click="() => this.$bvModal.show('bv-add-address')"
              >
                Add New Address
              </b-button>
              <b-modal
                id="bv-add-address"
                size="lg"
                :hide-footer="true"
                :hide-header="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
                centered
                header-class="my-class"
                body-class="p-0"
              >
                <template
                  v-if="$options.components['address-add-address-modal']"
                >
                  <address-add-address-modal />
                </template>
              </b-modal>
            </div>
          </div>
        </div>
        <div class="mt-4 text-center" v-if="isLoading">
          <p>It may take few seconds to load available Healthian Packages</p>
          <b-card v-for="i in 3" :key="i" class="mb-3">
            <b-skeleton class="mb-2" animation="wave" width="85%"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="55%"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="70%"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="80%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
          </b-card>
        </div>
        <sections-slider
          :list="packageList"
          subTitle="Our Recommended Packages"
          class="mt-3"
          :hasBackground="true"
          :showTitle="true"
          :hasButton="false"
          :withoutSlider="true"
          v-if="packageList.length > 0"
        >
          <template slot="default" slot-scope="{ listItem }">
            <!-- <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: `package_details`,
                params: { profile: listItem.deal_id },
              }"
            > -->
            <div class="item-box ml-2">
              <items-healthian-package
                :product="listItem"
                :viewProduct="{
                  name: `package_details`,
                  params: { profile: listItem.deal_id },
                }"
                :bookItem="{
                  name: `package_book`,
                  params: { profile: listItem.deal_id },
                }"
              >
              </items-healthian-package>
            </div>
            <!-- </router-link> -->
          </template>
        </sections-slider>
        <template
          v-if="$options.components['advertisement-healthians-middle-banner']"
        >
          <advertisement-healthians-middle-banner />
        </template>
        <template
          v-if="$options.components['advertisement-healthians-bottom-banner']"
        >
          <advertisement-healthians-bottom-banner />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import { profile } from "console";
import { mapState } from "vuex";

export default {
  data() {
    return {
      healthMarketId: "628371f94b367714f38740e6",
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      packageList: (state) => state.healthCare.specificPackageList,
      isServiceAvailable: (state) => state.healthCare.isServiceAvailable,
      categoryList: (state) => state.healthCare.healthCareCategoryList,
      healthProducts: (state) => state.healthCare.healthProducts,
      healthCategoryName: (state) => state.healthCare.healthCategoryName,
      productTypeData: (state) => state.healthCare.productTypeData,
      defaultAddress: (state) => state.address.defaultAddress,
      marketList: (state) => state.home.marketList,
    }),
  },
  watch: {
    async defaultAddress() {
      await this.checkServiceAvailability();
      await this.fetchHealthiansPackage();
    },
  },
  methods: {
    async fetchHealthiansPackage() {
      this.isLoading = true;
      let payload = {
        zipCode: this.defaultAddress?.zip,
        limit: 2,
      };
      await this.$store.dispatch("healthCare/getPackageByZip", payload);
      this.isLoading = false;
    },
    editAddressClick(e) {
      this.$emit("editAddress", e);
    },
    fetchHealthiansProduct() {
      let payload = {
        category: this.healthCategoryName,
      };
      this.$store.dispatch("healthCare/fetchHealthProductAction", payload);
    },
    async fetchProductData() {
      const payloadData = {
        categoryId: this.productTypeData._id,
        offset: 0,
        itemType: "SERVICE",
      };
      await this.$store.dispatch("healthCare/getProductData", payloadData);
    },
    async checkServiceAvailability() {
      let payload = {
        zipcode: Number(this.defaultAddress?.zip),
        lat: this.defaultAddress?.location?.coordinates[0].toString(),
        long: this.defaultAddress?.location?.coordinates[1].toString(),
      };
      await this.$store.dispatch(
        "healthCare/checkHealthServiceAction",
        payload
      );
    },
  },
  async mounted() {
    this.$store.commit("theme/updateTheme", {});
    this.$store.commit("theme/updateName", "");
    await this.$store.dispatch(
      "healthCare/getMarketColorTheme",
      this.healthMarketId
    );
    await this.$store.dispatch(
      "healthCare/getProductTypeAction",
      this.healthMarketId
    );
    await this.checkServiceAvailability();
    await this.fetchHealthiansProduct();
    await this.fetchProductData();
    await this.fetchHealthiansPackage();
  },

  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.text-grey1 {
  color: #aaa;
  background-color: transparent;
  margin-top: 20px;
  margin-left: 10px;
}

.address-banner-box {
  position: relative;
}

.over-img-box {
  position: absolute;
  width: 50%;
  z-index: 1;
  top: 20px;
  right: 70px;
}

.adress-box p {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 500;
}

.adress-box h6 {
  font-size: 14px;
  margin-bottom: 0px;
}

.adress-box {
  background-color: #fff;
  border-radius: 10px;
}

.text-box p {
  margin-bottom: 0px;
  color: #fff;
  font-weight: 500;
}

.icon-col {
  color: #c4c4c4;
}

.default-class {
  color: #47ab51;
  font-weight: 500;
}

.section-logos .v-slide-group {
  /* background-color: var(--secondary); */
  height: 140px !important;
  /* padding: 21px 27px 21px 27px;
  margin-top: -40px; */
}

/* product in slider  */

.product-box {
  border: 1px solid #b7e4e7;
  border-radius: 10px;
  max-width: 30%;
}

.product-box p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #00a0a8;
}

.product-box h5 {
  /* margin-bottom: 0px; */
  color: #00a0a8;
  font-size: 21px;
  font-weight: 500;
}

/* healthian package  */
.package-box {
  background-color: #fff;
  border: 1px solid #272727;
  border-radius: 10px;
  width: 220px;
}

.top-box p {
  font-size: 15px;
  font-weight: 600;
}

.icon-size {
  margin-top: 3px;
  height: 10px;
  width: 10px;
}

.mid-p {
  font-size: 13px;
  margin-bottom: 10px;
}

.small-p {
  font-size: 12px;
  margin-bottom: 7px;
}

#star8 {
  border-radius: 6px;
  background: red;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotate(00deg);
  -moz-transform: rotate(00deg);
  -ms-transform: rotate(00deg);
  -o-transform: rotate(00deg);
}

#star8:before {
  border-radius: 6px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  background: red;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

#star8 p {
  position: absolute;
  margin: 0;
  z-index: 9;
  color: #fff;
  top: 9%;
  word-wrap: break-word;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 20px;
}
</style>
