var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "3"
    }
  }, [_vm.$options.components['health-care-category'] ? [_c('health-care-category')] : _vm._e()], 2), _c('b-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "9"
    }
  }, [_vm.$options.components['advertisement-healthians-top-banner'] ? [_c('advertisement-healthians-top-banner')] : _vm._e(), _c('span', {
    staticClass: "text-grey1"
  }, [_vm._v("Home > Looking for a doctor ")]), _c('sections-slider', {
    attrs: {
      "list": _vm.healthProducts,
      "hasInnerBackground": true,
      "hasButton": false,
      "withSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('items-healthian-product', {
          attrs: {
            "product": listItem
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "address-banner-box mb-3"
  }, [_c('div', [_c('b-img', {
    attrs: {
      "src": "img/address_banner.png",
      "fluid": ""
    }
  })], 1), _c('div', {
    staticClass: "over-img-box"
  }, [_c('div', {
    staticClass: "text-box"
  }, [_c('p', [_vm._v("Selected Pickup Location")])]), _c('div', {
    staticClass: "adress-box p-3 mt-2"
  }, [_vm.isServiceAvailable ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-icon', {
    staticClass: "mr-3 icon-col",
    attrs: {
      "icon": "arrow-up-circle-fill"
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.defaultAddress ? _vm.defaultAddress.fullName : ""))])], 1), _c('div', {
    staticClass: "d-flex default-class"
  }, [_c('b-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "check2",
      "font-scale": "1"
    }
  }), _c('p', [_vm._v("Default")])], 1)]), _c('div', {
    staticClass: "d-flex mt-2"
  }, [_c('b-icon', {
    staticClass: "mr-3 mt-1 icon-col",
    attrs: {
      "icon": "geo-alt-fill"
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.houseNumber : "") + ", " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.street2 : "") + ", " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.city : "") + ","), _c('br'), _vm._v(" " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.state : "") + ", PIN- " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.zip : "") + " ")])], 1)]) : _c('p', [_vm._v("Please add a default address")])]), _c('div', {
    staticClass: "button-box d-flex justify-content-end mt-2"
  }, [_vm.isServiceAvailable ? _c('b-button', {
    staticClass: "button-text mr-3",
    attrs: {
      "type": "button",
      "variant": "outline-primary"
    },
    on: {
      "click": function click() {
        _vm.editAddressClick(_vm.defaultAddress);

        _vm.$bvModal.show(_vm.defaultAddress._id);
      }
    }
  }, [_vm._v(" Edit Address ")]) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": _vm.defaultAddress._id,
      "size": "lg",
      "hide-footer": true,
      "hide-header": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
      "centered": "",
      "header-class": "my-class",
      "body-class": "p-0"
    }
  }, [_vm.$options.components['address-edit-address-modal'] ? [_c('address-edit-address-modal', {
    ref: "editAddressRef",
    attrs: {
      "address": _vm.defaultAddress
    }
  })] : _vm._e()], 2), _c('b-button', {
    staticClass: "button-text ml-3",
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": function click() {
        return _this.$bvModal.show('bv-add-address');
      }
    }
  }, [_vm._v(" Add New Address ")]), _c('b-modal', {
    attrs: {
      "id": "bv-add-address",
      "size": "lg",
      "hide-footer": true,
      "hide-header": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
      "centered": "",
      "header-class": "my-class",
      "body-class": "p-0"
    }
  }, [_vm.$options.components['address-add-address-modal'] ? [_c('address-add-address-modal')] : _vm._e()], 2)], 1)])]), _vm.isLoading ? _c('div', {
    staticClass: "mt-4 text-center"
  }, [_c('p', [_vm._v("It may take few seconds to load available Healthian Packages")]), _vm._l(3, function (i) {
    return _c('b-card', {
      key: i,
      staticClass: "mb-3"
    }, [_c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "85%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "55%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "70%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "80%"
      }
    }), _c('b-skeleton', {
      attrs: {
        "animation": "wave",
        "width": "70%"
      }
    })], 1);
  })], 2) : _vm._e(), _vm.packageList.length > 0 ? _c('sections-slider', {
    staticClass: "mt-3",
    attrs: {
      "list": _vm.packageList,
      "subTitle": "Our Recommended Packages",
      "hasBackground": true,
      "showTitle": true,
      "hasButton": false,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box ml-2"
        }, [_c('items-healthian-package', {
          attrs: {
            "product": listItem,
            "viewProduct": {
              name: "package_details",
              params: {
                profile: listItem.deal_id
              }
            },
            "bookItem": {
              name: "package_book",
              params: {
                profile: listItem.deal_id
              }
            }
          }
        })], 1)];
      }
    }], null, false, 3304255709)
  }) : _vm._e(), _vm.$options.components['advertisement-healthians-middle-banner'] ? [_c('advertisement-healthians-middle-banner')] : _vm._e(), _vm.$options.components['advertisement-healthians-bottom-banner'] ? [_c('advertisement-healthians-bottom-banner')] : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }